import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import defaultCam from '../../../assets/images/graphics/default-cam.svg';
import personOutline from '../../../assets/images/graphics/person.svg';
import { Button } from '../../../ui/button/button';
import { GreenTick, RedCross } from '../../../ui/icons/icons';
import { SecondaryTypography } from '../../../ui/typography/typography';
import { awsUpload, detectFace, getStorageLink } from '../../../utils/api_service/storage_api';
import { updateProfilePic } from '../../../utils/api_service/user_api';
import { IS_IOS } from '../../assessment/perform/assigned_assessments/assigned_assessments';
import styles from './LiveCameraModal.module.css';

type LiveCameraModalProps = {
  setProfileLink: (content: any) => void,
  setIsModalOpen: (content: boolean) => void,
}

export const LiveCameraModal = ({
  setProfileLink,
  setIsModalOpen,
}: LiveCameraModalProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const photoRef = useRef<HTMLCanvasElement>(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [verified, setVerified] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    getUserCamera();
  },[videoRef]);

  useEffect(() => {
    const processImage = async () => {
      if (isProcessing) {
        const video = videoRef.current;
        const photo = photoRef.current;
        if (video && photo) {
          const ctx = photo.getContext('2d');
          ctx?.drawImage(video, 0, 0, 256, 190);
          // const dataURL = photo.toDataURL('image/png');
          setDisabled(false);
          setVerified(true);
        }
        setIsProcessing(false); // Set processing to false after image processing is done
      }
    };
    processImage();
  }, [isProcessing]);

  useEffect(() => {
    const canvas = photoRef.current;
    const image = new Image();
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        image.src = defaultCam;
        image.onload = () => {
          ctx.drawImage(image, 0, 0, 256, 190);
        };
      }
    }
  },[]);

  // const detectFaceImage = async (image: string) => {
  //   try {
  //     const response = await detectFace(image);
  //     const responseData = await response.json();
  //
  //     if (responseData.detected) {
  //       setDisabled(false);
  //       setVerified(true);
  //     } else {
  //       setDisabled(true);
  //       setVerified(false);
  //     }
  //   } catch (error) {
  //     toast.error('Error Detecting Face', { position: 'bottom-center' });
  //   }
  // };

  const takePicture = () => {
    setIsProcessing(true); // Set processing to true when starting image processing
  };

  const capturePicture = (imageName: string) => {
    const folderName = 'profile-pic';
    const photo = photoRef.current;
    setIsModalOpen(false);
    if (photo) {
      photo.toBlob((blob) => {
        if (blob) {
          uploadPicture(imageName, folderName, blob);
        }
      }, 'image/jpeg', 0.9);
    }
  };

  const getUserCamera = () => {
    navigator.mediaDevices.getUserMedia({
      video: true,
    })
      .then((stream) => {
        let video = videoRef.current;
        if (video) {
          video.muted = true;
          video.srcObject = stream;
          video.autoplay = true;
        }
      });
  };

  const uploadPicture = (fileName: string, folderName: string, image: Blob) => {
    getStorageLink(fileName, folderName).then(response => {
      awsUpload(response.url, image);
      updateProfilePic(response.fileName).then(response => {
        setProfileLink(response.profilePic);
        localStorage.setItem('profilePic', response.profilePic);
      });
    });
  };

  return (
    <div>

      <div
          className ={styles.photoArea}
      >
        <div
            className={styles.liveCamera}
        >
          <div
              className={styles.camSection}
          >
            <div
                className={styles.camLabels}
            >
              <SecondaryTypography.Medium
                  fontWeight='semi-bold'
              >
                Live Webcam
              </SecondaryTypography.Medium>
            </div>
            <div className='relative w-full h-full'>
              { IS_IOS ? (
                <video
                    className={styles.videoStreamContainer}
                    ref={videoRef}
                    autoPlay={true}
                    playsInline={true}
                    muted={true}
                />
              ) : (
                <video
                    className={styles.videoStreamContainer}
                    ref={videoRef}
                />
              )
              }
              <div
                  className='absolute top-0 left-0 w-full h-full bg-black opacity-30 rounded-lg'
              />
              <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[265px] h-[265px] pointer-events-none'>
                <img
                    src={personOutline}
                />
              </div>
            </div>
          </div>
          <div
              className={styles.camSection}>
            <div
                className={styles.camLabels}>
              <SecondaryTypography.Medium
                  fontWeight='semi-bold'
              >
                Selected Image
              </SecondaryTypography.Medium>
            </div>
            <div className='relative text-center'>
              <canvas
                  className={styles.videoStreamContainer} width='256' height='190' ref={photoRef}>
              </canvas>
              { isProcessing && <div className='absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 rounded font-bold w-64' style={{ top: '85%' }}>
                Processing image<br />
                Please wait...
              </div> }
            </div>
          </div>
        </div>
        <div>
        </div>
        <div className ={styles.faceInfo}>
          <div
              className={styles.faceDetailsArea}
          >
            <div
                className={styles.faceAnalysis}
                style={{ paddingLeft: '18rem' }}
            >
              <div
                  className={styles.faceDetail}
              >
                <SecondaryTypography.Medium
                    fontWeight='semi-bold'
                >
                  Full face
                </SecondaryTypography.Medium>
                <div
                    className={styles.tickCrossArea}
                >
                  { verified ? (
                    <GreenTick/>
                  ) : (
                    <RedCross/>
                  ) }

                </div>
              </div>
              <div
                  className={styles.faceDetail}
              >
                <SecondaryTypography.Medium
                    fontWeight='semi-bold'
                >
                  Lighting
                </SecondaryTypography.Medium>
                <div
                    className={styles.tickCrossArea}
                >
                  { verified ? (
                    <GreenTick/>
                  ) : (
                    <RedCross/>
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            className={styles.buttonSection}
        >
          <Button
              onClick={() => takePicture()}
              variant={'primary'}
              size={'medium'}
              customClass={styles.buttonStyles}
          >
            Take Picture
          </Button>
          <Button
              onClick={() => capturePicture('test.jpg')}
              variant={'primary'}
              size={'medium'}
              customClass={styles.buttonStyles}
              disabled={disabled}
          >
            Save Picture
          </Button>
        </div>
      </div>
    </div>
  );
};
